.sign-in {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;

  .title {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: 30px;
    color: rgb(138, 23, 49);
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 21px;
  }

  .disclaimer {
    text-align: left;
    margin-bottom: 30px;
  }
}