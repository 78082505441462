.footer-image {
  background-color: rgb(245, 245, 245);

  .footer-image-container {
    width: 100%;
    max-width: 1920px;
    height: 450px;
    margin: 0 auto;
    // background-image: url(https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/Tax_Consultants-James-Wailuku-003-1920w.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    // align-items: bottom;
    
    .message-box {
      width: 1000px;
      min-height: 200px;
      height: max-content;
      margin-top: auto;
      margin-bottom: 5%;
      background-color: rgba(28, 39, 48, .5);

      display: inline-flex;
      align-items: center;

      .message-icon {
        width: 225px;

        .phone-call-icon {
          width: 225px;
        }
      }

      .message-body {
        max-width: 475px;
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
        color: rgb(250, 250, 250);
      }

      .button-container {
        min-width: 200px;
        float: right;
        margin: 0 auto;
      }
    }
  }

  @media all and (max-width: 929px) {

    .footer-image-container {
      height: 320px;
      
      .message-box {
        text-align: center;
  
        .message-icon {
          display: none;
        }
      }
    }
  }

  @media all and (max-width: 760px) {

    .footer-image-container {
      // height: 320px;

      .message-box {
        display: grid !important;
        text-align: center;
        justify-content: center;
      }

      // .message-icon {
      //   display: none;
      // }
    }

  }
  
}