.header {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 73px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 10;
  clear: both;

  .header-container-nav {

    height: 50px;
    width: 100%;
    max-width: 1105px;
    margin: 0 auto;
    padding: 13px 10px;

    .logo-container {
      float: left;

      .logo {
        height: 82px;
        // width: 300px;
      }

      // .header-logo-mobile {
      //   display: none;
      // }

    }

    .nav-container {
      float: right;

      .nav-link {
        padding: 6px 13px;
        color: rgb(19, 19, 19);
        // line-height: 40px;
        font-size: 16px;
        letter-spacing: .51px;
        line-height: 35px;
  
        &:hover {
          color: rgba(138,23,49,1);
        }
      }

      .nav-dropdown {
        display: inline-flex;
        position: relative;

        .arrow {
          // display: inline-block;
          width: 8px;
          height: 8px;
          border-top: 1px solid #000;
          border-right: 1px solid #000;
          margin: 12px 0 0 8px;
          transition:  .25s ease-in-out;
          transform: translate(0, 120px);
        }

        .arrow-bottom {
          transform: rotate(135deg);
        }

        &:hover {
          .arrow-bottom {
            margin: 14px 0 0 8px;
            transform: rotate(315deg);
          }
          .test {
            opacity: 1;
            visibility: visible;
            transition:  .2s ease-in;
            transform: translateY(5px);
          }
        }
        .test {
          width: max-content;
          position: absolute;
          top: 40px;
          left: 0;
          background-color: white;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #000;
          visibility: hidden;
          opacity: 0;
          transition:  .1s ease-out;
  
        }
      }
    }

    .phone-icon {
      transform: rotate(90deg) scale(1.10);
      margin-right: 5px;
    }

    .mobile-nav-container {
      // display: none;
      flex-wrap: wrap;
      // justify-content: space-between;
      align-items: center;
      text-align: center;
      margin-right: 10px;
      grid-template-columns: 1fr .25fr;
      // gap: 10px;
      float: right;

      .mobile-menu{
        margin: 0 auto;
        width: 50px;
        // padding-right: 30px;

        .bar1, .bar2, .bar3 {
          width: 35px;
          height: 5px;
          border-radius: 5px;
          background-color: rgb(138,23,49);
          margin: 6px auto;
          transition: 0.4s;
        }

        .change {

          &.bar1 {
            transform: rotate(-45deg) translate(-8px, 8px);
          }

          &.bar2 {opacity: 0;}

          &.bar3 {
            transform: rotate(45deg) translate(-8px, -8px);
          }

        }

      }
      
    }
  
  }

  
  //   position: fixed;
  // height: 50px;
  // width: 100%;
  // max-width: 1200px;
  // margin: 0 auto;
  // display: flex;
  // // justify-content: center;
  // align-items: center;
  // border: 3px solid green;


  //   .logo-container {
  //     padding: 15px;
  //     box-sizing:content-box;
  //     float: left;
  //     // margin: auto auto auto 100px;
       
  //     .logo {
  //       height: 90px;
  //       width: 300px;
  //     }
  //   }
  
  //   .options {
  //     // width: 50%;
  //     // height: 100%;
  //     // display: flex;
  //     // align-items: center;
  //     // justify-content: flex-end;
  //     background: green;
  //     display: inline-block;

  //     float: right;
  
  //     .option {
  //       padding: 10px 15px;
  //       cursor: pointer;
  //       text-decoration: none;
  //       color: rgb(19, 19, 19);
  
  //       &:hover {
  //         color: rgba(138,23,49,1);
  //       }
  //     }
  
  //     .phone-number {
  //       width: 147px;
  //       height: 48px;
  //       border-color: rgba(138,23,49,1);
  //       border-width: 1px;
  //       border-style: solid;
  //       text-decoration: none;
  //       text-align: center;
  //       line-height: 48px;
  //       color: rgba(138,23,49,1);
  
  //       &:hover {
  //         color: #fff;
  //         background-color: rgba(138,23,49,1);
  //       }
  //     }
  //   }
  // }
  
}

.mobile-menu-dropdown {
  position: fixed;
  top: 68px;
  width: 100vw;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9;
  padding-top: 10px;
  visibility: hidden;
  opacity: 0;

  .nav-link-mobile {
    // margin: 0 auto;
    width: 100vw;
    padding: 100px 0;

    text-align: center;
    padding: 10px;
    border-bottom: solid rgba(0, 0, 0, 0.35);

    &:hover {
      color: rgba(138,23,49,1);
    }
  }
}

.visable {
  opacity: 1;
  visibility: visible;
  transition: .2s ease-in;
  transform: translateY(5px);
}